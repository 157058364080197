// simplebar styles
@import "simplebar/dist/simplebar.css";

// Configuration
@import "@coreui/coreui-pro/scss/functions";

@import "stylesheets/theme-colors";

@import "@coreui/coreui-pro/scss/variables";
@import "@coreui/coreui-pro/scss/maps";
@import "@coreui/coreui-pro/scss/mixins";

// If you want to override variables do it here
@import "stylesheets/variables";

@import "@coreui/coreui-pro/scss/utilities";

// Layout & components
@import "@coreui/coreui-pro/scss/root";
@import "@coreui/coreui-pro/scss/reboot";
@import "@coreui/coreui-pro/scss/type";
@import "@coreui/coreui-pro/scss/images";
@import "@coreui/coreui-pro/scss/containers";
@import "@coreui/coreui-pro/scss/grid";
@import "@coreui/coreui-pro/scss/tables";
@import "@coreui/coreui-pro/scss/forms";
@import "@coreui/coreui-pro/scss/buttons";
@import "@coreui/coreui-pro/scss/loading-button";
@import "@coreui/coreui-pro/scss/transitions";
@import "@coreui/coreui-pro/scss/dropdown";
@import "@coreui/coreui-pro/scss/button-group";
@import "@coreui/coreui-pro/scss/nav";
@import "@coreui/coreui-pro/scss/navbar";
@import "@coreui/coreui-pro/scss/card";
@import "@coreui/coreui-pro/scss/accordion";
@import "@coreui/coreui-pro/scss/breadcrumb";
@import "@coreui/coreui-pro/scss/pagination";
@import "@coreui/coreui-pro/scss/badge";
@import "@coreui/coreui-pro/scss/alert";
@import "@coreui/coreui-pro/scss/progress";
@import "@coreui/coreui-pro/scss/list-group";
@import "@coreui/coreui-pro/scss/close";
@import "@coreui/coreui-pro/scss/toasts";
@import "@coreui/coreui-pro/scss/modal";
@import "@coreui/coreui-pro/scss/tooltip";
@import "@coreui/coreui-pro/scss/popover";
@import "@coreui/coreui-pro/scss/carousel";
@import "@coreui/coreui-pro/scss/spinners";
@import "@coreui/coreui-pro/scss/offcanvas";
@import "@coreui/coreui-pro/scss/placeholders";

@import "@coreui/coreui-pro/scss/avatar";
@import "@coreui/coreui-pro/scss/callout";
@import "@coreui/coreui-pro/scss/footer";
@import "@coreui/coreui-pro/scss/header";
@import "@coreui/coreui-pro/scss/icon";
@import "@coreui/coreui-pro/scss/sidebar";
@import "@coreui/coreui-pro/scss/subheader";

// Helpers
@import "@coreui/coreui-pro/scss/helpers";

// Utilities
@import "@coreui/coreui-pro/scss/utilities/api";
@import "@coreui/coreui-pro/scss/utilities/bg-gradients";

@import "stylesheets/layout";
@import "@coreui/coreui-pro/scss/themes/dark";

// If you want to add custom CSS you can put it here.
@import "stylesheets/custom";

// We use those styles to show code examples, you should remove them in your application.
@import "stylesheets/example";
